import React, { useMemo, useState } from 'react'
import { message, Col, List, Row, Typography, Flex } from 'antd';

import styles from "../../Orders.module.scss";

import ListItem from '../ListItem';
import { getPayStatusByValue } from '../../util/common';
import StatusBadge from '../../../../components/StatusBadge';
import PayTypeBadgeAlt from '../../../../components/PayTypeBadgeAlt';
import RecycleOrdersApi from '../../../../apis/order-management/recycle-order/RecycleOrdersApi';
import { EditOutlined } from '@ant-design/icons';
import EditAddressFields from '../../../../components/EditAddressFields';

const { Text } = Typography;

export default function MoreInfo({ activeOrder, setActiveOrder, baseUrl, setActiveKey, }) {
    function addressText(obj) {
        return `${obj.address}, ${obj.address_alt ? obj.address_alt : ""}${(obj?.address_alt && obj?.address_alt.length === 0) ? "" : ", "} ${obj.postal_code},  ${obj.city},  ${obj?.country?.name}.`;
    }

    const statusObj = getPayStatusByValue(activeOrder.is_paid);
    const [isEditAddressModalOpen, setIsEditAddressModalOpen] = useState(false);
    const [editAddressType, setEditAddressType] = useState('');
    const collection_date = useMemo(() => activeOrder.order_shipments || "-", [activeOrder]);

    const handleUpdate = async (fieldType, newValue) => {
        if (activeOrder?.user[fieldType] === newValue) return;

        try {
            if (!activeOrder)
                throw new Error('Something went wrong!');

            const updatedOrder = await RecycleOrdersApi.updateOneField(baseUrl, activeOrder.id, {
                fieldType: fieldType,
                newValue: newValue
            });

            if (updatedOrder) {
                setActiveKey(prevState => prevState + 1);
                setActiveOrder(prevOrder => ({
                    ...prevOrder,
                    [fieldType]: newValue
                }));
                message.success(`${fieldType} Updated!`);
            }
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
        }
    };

    const payInfo = useMemo(() => {
        const paymentDetails = activeOrder?.type !== "recycle" ? activeOrder?.payment_details : JSON.parse(activeOrder?.payment_details?.data);
        const paypal_username = paymentDetails?.paypal_username || paymentDetails?.email;

        if (paypal_username) {
            return [
                {
                    keyText: 'Paypal Username',
                    value: paypal_username,
                    valueCopyable: true,
                }
            ];
        }

        return [
            {
                keyText: 'Account Holder',
                value: paymentDetails?.account_name,
                valueCopyable: true,
            },
            {
                keyText: 'Account Number',
                value: paymentDetails?.account_no || paymentDetails?.account_number,
                valueCopyable: true,
            },
            {
                keyText: 'Sort Code',
                value: paymentDetails?.sort_code,
                valueCopyable: true,
            },
        ]
    }, [activeOrder?.payment_details]);

    const showModal = (type) => {
        setEditAddressType(type);
        setIsEditAddressModalOpen(true);
    };

    let PostageOptions = [
        { key: 'door-collection', label: 'Next-Day Door Collection' },
        { key: 'royal-mail', label: 'Send me a FREE Label' },
        { key: 'own-courier', label: 'I will use my courier' }
    ];
    return (
        <>
            <Row gutter={16} className={styles.model_card_row}>
                <Col span={8}>
                    <List
                        size="small"
                        header={<Text>Summery</Text>}
                        bordered
                        dataSource={[
                            <ListItem key={0}
                                keyText="Order # : "
                                valueText={activeOrder.order_no} valueCopyable />,
                            <ListItem key={1}
                                keyText="Payment method : "
                                valueText={(
                                    <Flex>
                                        <StatusBadge value={statusObj.value} color={statusObj.color} label={statusObj.label} />
                                        <PayTypeBadgeAlt type={!(activeOrder?.payment_details?.paypal_username || (activeOrder?.type == "recycle" && JSON.parse(activeOrder?.payment_details?.data)?.email)) ? 'bank' : 'paypal'} />
                                    </Flex>
                                )}
                            />,
                            ...payInfo.map((item, index) => (
                                <ListItem
                                    key={index}
                                    keyText={item.keyText + " : "}
                                    valueText={item.value}
                                    valueCopyable={item.valueCopyable}
                                />
                            ))
                        ]}
                        renderItem={item => <List.Item>{item}</List.Item>}
                    />
                </Col>

                <Col span={8}>
                    <List
                        size="small"
                        header={<Text>Contact</Text>}
                        bordered
                        dataSource={[
                            <ListItem key={0}
                                keyText="Name: "
                                fieldType="name"
                                valueText={`${activeOrder?.user?.first_name || activeOrder?.first_name} ${(activeOrder?.user?.last_name || activeOrder?.last_name) ? activeOrder?.user?.last_name || activeOrder.last_name : ''}`}
                                valueCopyable
                                onUpdate={(fieldType, newValue) => handleUpdate(fieldType, newValue)}
                            />,
                            <ListItem key={1}
                                keyText="E-Mail: "
                                editable={activeOrder?.type !== "recycle" ? true : false}
                                fieldType="email"
                                valueText={activeOrder?.user?.email}
                                valueCopyable
                                onUpdate={(fieldType, newValue) => handleUpdate(fieldType, newValue)}
                            />,
                            <ListItem key={2}
                                keyText="Phone: "
                                editable={activeOrder?.type !== "recycle" ? true : false}
                                fieldType="phone_no"
                                valueText={activeOrder?.user?.phone_no || activeOrder?.user?.company?.phone_number}
                                valueCopyable
                                onUpdate={(fieldType, newValue) => handleUpdate(fieldType, newValue)}
                            />,
                        ]}
                        renderItem={item => <List.Item>{item}</List.Item>}
                    />
                </Col>

                <Col span={8}>
                    <List
                        size="small"
                        header={
                            <Flex
                                align='center'
                                justify='space-between'
                            >
                                <Text>Shipping Address</Text>
                                <EditOutlined
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => showModal('shipping')}
                                />
                            </Flex>
                        }
                        bordered
                        dataSource={[
                            ...addressText(activeOrder?.user.postal_code ? activeOrder?.user : activeOrder).split(', '),
                        ]}
                        renderItem={item => (
                            <List.Item>
                                <ListItem
                                    key={0}
                                    valueText={item}
                                    valueCopyable={(typeof item === 'string') && ((activeOrder?.user?.postal_code || activeOrder?.postal_code)?.replace(/\s/g, "") == item?.replace(/\s/g, ""))}
                                />
                            </List.Item>
                        )}
                    />
                    {collection_date?.ship_method &&
                        <List
                            size="small"
                            className='mt-3'
                            header="Postage Options"
                            bordered
                            dataSource={[
                                <ListItem
                                    key={2}
                                    keyText="Postage Options:"
                                    valueText={collection_date?.ship_method ? PostageOptions.find(option => option.key === collection_date?.ship_method)?.label : "-"}
                                />,
                                <ListItem
                                    key={2}
                                    keyText="Collection Date:"
                                    valueText={collection_date?.collection_date ? collection_date?.collection_date : "-"}
                                    isMark={collection_date?.collection_date}
                                />
                            ]}
                            renderItem={item => <List.Item>{item}</List.Item>}
                        />
                    }
                </Col>
            </Row>
            <Row gutter={16} className={styles.model_card_row}>
                {(activeOrder?.customer_address || activeOrder?.user?.company) &&
                    <Col span={6}>
                        <List
                            size="small"
                            header={
                                <Flex
                                    align='center'
                                    justify='space-between'
                                >
                                    <Text>Billing Address</Text>
                                    <EditOutlined
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => showModal('billing')}
                                    />
                                </Flex>
                            }
                            bordered
                            dataSource={[
                                ...addressText(activeOrder?.customer_address || activeOrder?.user?.company).split(', '),
                            ]}
                            renderItem={item => (
                                <List.Item>
                                    <ListItem
                                        key={0}
                                        valueText={item}
                                        valueCopyable={(typeof item === 'string') && ((activeOrder?.customer_address?.postal_code.replace(/\s/g, "") || activeOrder?.user?.company?.postal_code.replace(/\s/g, "")
                                        ) == item.replace(/\s/g, ""))}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                }

                {activeOrder.user &&
                    activeOrder.user.company &&
                    <Col span={6}>
                        <List
                            size="small"
                            header={<Text>Business Information</Text>}
                            bordered
                            dataSource={[
                                <ListItem key={0}
                                    keyText="Company Name: "
                                    valueText={activeOrder.user.company.name}
                                    valueCopyable
                                />,
                                ...addressText(activeOrder.user.company).split(', '),
                            ]}
                            renderItem={item => (
                                <List.Item>
                                    <ListItem
                                        key={0}
                                        valueText={item}
                                        valueCopyable={(typeof item === 'string') && (activeOrder.user.company?.postal_code.replace(/\s/g, "") == item.replace(/\s/g, ""))}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                }

                {activeOrder.user &&
                    <Col span={6}>
                        <List
                            size="small"
                            header={<Text>User Information</Text>}
                            bordered
                            dataSource={[
                                <ListItem key={0}
                                    keyText="Name: "
                                    valueText={`${activeOrder?.user?.first_name || activeOrder?.first_name} ${(activeOrder?.user?.last_name || activeOrder?.last_name) ? activeOrder?.user?.last_name || activeOrder.last_name : ''}`}
                                    valueCopyable
                                />,
                                <ListItem key={1}
                                    keyText="E-Mail : "
                                    valueText={activeOrder.user.email} valueCopyable
                                />,
                            ]}
                            renderItem={item => <List.Item>{item}</List.Item>}
                        />
                    </Col>
                }

                <EditAddressFields
                    isEditAddressModalOpen={isEditAddressModalOpen}
                    setIsEditAddressModalOpen={setIsEditAddressModalOpen}
                    editAddressType={editAddressType}
                    orderType="recycle"
                    setActiveKey={setActiveKey}
                    activeOrder={activeOrder}
                    baseUrl={baseUrl}
                />
            </Row>
        </>
    )
}
