import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    shippingOrdersCollection: []
};

const shippingOrdersCollectionSlice = createSlice({
    name: 'shippingOrdersCollection',
    initialState,
    reducers: {
        addShippingOrderCollection: (state, action) => {
            state.shippingOrdersCollection.push(action.payload);
        },
        deleteShippingOrderCollection: (state, action) => {
            state.shippingOrdersCollection = state.shippingOrdersCollection.filter(order => order.id !== action.payload);
        }
    }
});

export const { addShippingOrderCollection, deleteShippingOrderCollection } = shippingOrdersCollectionSlice.actions;

export default shippingOrdersCollectionSlice.reducer;
