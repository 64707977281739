import React, { useState, useMemo } from 'react';
import { Button, Col, Flex, List, message, Row, Typography } from 'antd';
import { EditOutlined, SendOutlined } from '@ant-design/icons';
import { SiDpd } from "react-icons/si";
import { TbStatusChange } from "react-icons/tb";
import { BiLinkExternal } from "react-icons/bi";

import styles from "../../Orders.module.scss";

import OrdersApi from '../../../../apis/order-management/repair-order/OrdersApi';
import ListItem from '../ListItem';
import { getPayStatusByValue } from '../../util/common';
import StatusBadge from '../../../../components/StatusBadge';
import PayTypeBadge from '../../../../components/PayTypeBadge';
import EditAddressFields from '../../../../components/EditAddressFields';

const { Text } = Typography;

export default function MoreInfo({ activeOrder, priorityCharge, discount, baseUrl, setActiveOrder, setActiveKey }) {
    const [loadingObj, setLoadingObj] = useState({
        exportRoyalMail: false,
        exportDPD: false,
        copyTrackingCode: false
    });

    const [isEditAddressModalOpen, setIsEditAddressModalOpen] = useState(false);
    const [editAddressType, setEditAddressType] = useState('');

    function addressText(obj) {
        return `${obj.address}, ${obj.address_alt ? obj.address_alt : ""}${(obj?.address_alt && obj?.address_alt.length === 0) ? "" : ", "} ${obj.postal_code},  ${obj.city},  ${obj?.country?.name ?? 'United Kingdom'}.`;
    }

    const exportToRoyalMailHandler = async () => {
        setLoadingObj({
            ...loadingObj,
            exportRoyalMail: true
        });

        try {
            if (!activeOrder)
                throw new Error('Something went wrong!');

            const response = await OrdersApi.royalMailOrder(baseUrl, activeOrder.id);
            if (response) {
                message.success('Order was exported to Royal Mail!');
                setLoadingObj({
                    ...loadingObj,
                    exportRoyalMail: false
                });
            }
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
            setLoadingObj({
                ...loadingObj,
                exportRoyalMail: false
            });
        }
    }

    const exportToDPDHandler = async () => {
        setLoadingObj({
            ...loadingObj,
            exportDPD: true
        });

        try {
            if (!activeOrder)
                throw new Error('Something went wrong!');

            const response = await OrdersApi.createDPDShipment(baseUrl, activeOrder.id);
            if (response) {
                message.success('Order was exported to DPD!');
                setLoadingObj({
                    ...loadingObj,
                    exportDPD: false
                });
            }
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
            setLoadingObj({
                ...loadingObj,
                exportDPD: false
            });
        }
    }

    const getRoyalMailOrderHandler = async (isHref = false, copyToClipboard = true) => {
        setLoadingObj({
            ...loadingObj,
            copyTrackingCode: true
        });

        try {
            if (!activeOrder)
                throw new Error('Something went wrong!');

            const response = await OrdersApi.getRoyalMailOrder(baseUrl, activeOrder.id);
            if (response) {
                const trackingNumber = response[0].trackingNumber;
                if (isHref) {
                    window.open(`https://www.royalmail.com/track-your-item#/tracking-results/${trackingNumber}`, '_blank');
                }

                if (copyToClipboard) {
                    copyTextToClipboard(trackingNumber);
                }

                message.success('Royal Mail tracking has been found!');
                setLoadingObj({
                    ...loadingObj,
                    copyTrackingCode: false
                });
            }
        }
        catch (e) {
            message.error((e.message || e.error) ?? 'Something went wrong!');
            setLoadingObj({
                ...loadingObj,
                copyTrackingCode: false
            });
        }
    }

    const copyTextToClipboard = (text) => {
        const el = document.createElement('textarea');
        el.value = text;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        navigator.clipboard.writeText(el.value);
        document.body.removeChild(el);
    }

    const statusObj = getPayStatusByValue(activeOrder.is_paid);

    const handleUpdate = async (fieldType, newValue) => {
        if (activeOrder[fieldType] === newValue) return;

        try {
            if (!activeOrder)
                throw new Error('Something went wrong!');

            const requestBody = {
                [fieldType]: newValue
            };
            const response = await OrdersApi.updateField(baseUrl, activeOrder.id, requestBody);

            if (response) {
                setActiveOrder(prevOrder => ({
                    ...prevOrder,
                    [fieldType]: newValue
                }));
                message.success(`${fieldType} Updated!`);
            }
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
        }
    };

    const sendingShipment = useMemo(() => activeOrder.order_shipments?.find(shipment => shipment.is_shipping === 1 && shipment.type === 0)?.amount || "-", [activeOrder]);
    const returnShipping = useMemo(() => activeOrder.order_shipments?.find(shipment => shipment.is_shipping === 1 && shipment.type === 1)?.amount || "-", [activeOrder]);
    const collection_date = useMemo(() => activeOrder.order_shipments?.find(shipment => shipment.is_shipping === 1 && shipment.type === 0)?.collection_date || "-", [activeOrder]);

    const showModal = (type) => {
        setIsEditAddressModalOpen(true);
        setEditAddressType(type);
    };

    return (
        <Row gutter={[16, 16]} className={styles.model_card_row}>
            <Col span={8}>
                <List
                    size="small"
                    header={<Text>Summary</Text>}
                    bordered
                    dataSource={[
                        <ListItem key={0}
                            keyText="Order #: "
                            valueText={activeOrder.order_no} valueCopyable />,
                        <ListItem key={1}
                            keyText="Payment method: "
                            valueText={(
                                <Flex>
                                    <StatusBadge value={statusObj.value} color={statusObj.color} label={statusObj.label} />
                                    <PayTypeBadge type={activeOrder?.payment_type} />
                                </Flex>
                            )}
                        />,
                        <ListItem
                            key={2}
                            keyText="Collection Date:"
                            valueText={collection_date !== "-" ? collection_date : "-"}
                            isMark={collection_date !== "-"}
                        />,
                        <ListItem
                            key={3}
                            keyText="Postage Before:"
                            valueText={sendingShipment !== "-" ? "£" + parseFloat(sendingShipment).toFixed(2) : "-"}
                        />,
                        <ListItem
                            key={4}
                            keyText="Postage After:"
                            valueText={returnShipping !== "-" ? "£" + parseFloat(returnShipping).toFixed(2) : "-"}
                        />,
                        <ListItem key={5}
                            keyText="Priority charge: "
                            valueText={(activeOrder.order_priority && activeOrder?.order_priority.is_priority === 1) ? "£" + priorityCharge : "-"}
                        />,
                        <ListItem key={6}
                            keyText="Discount:"
                            valueText={activeOrder.coupon ? "£" + discount : "-"}
                        />,
                    ]}
                    renderItem={item => <List.Item>{item}</List.Item>}
                />
            </Col>

            <Col span={8}>
                <List
                    size="small"
                    header={<Text>Contact</Text>}
                    bordered
                    dataSource={[
                        <ListItem
                            key={0}
                            keyText="Name: "
                            valueText={`${activeOrder.first_name} ${activeOrder.last_name ? activeOrder.last_name : ""}`}
                            valueCopyable
                        />,
                        <ListItem
                            key={1}
                            keyText="E-Mail: "
                            valueText={activeOrder.email} valueCopyable
                            icon={<EditOutlined />}
                            fieldType="email"
                            editable={true}
                            onUpdate={(fieldType, newValue) => handleUpdate(fieldType, newValue)}
                        />,
                        <ListItem
                            key={2}
                            keyText="Phone: "
                            valueText={activeOrder.phone_number} valueCopyable
                            icon={<EditOutlined />}
                            editable={true}
                            fieldType="phone_number"
                            onUpdate={(fieldType, newValue) => handleUpdate(fieldType, newValue)}
                        />,
                    ]}
                    renderItem={item => <List.Item>{item}</List.Item>}
                />
            </Col>

            <Col span={8} />

            {activeOrder?.collection_address &&
                <Col span={8}>
                    <List
                        size="small"
                        header={
                            <Flex
                                justify='space-between'
                                align='center'
                            >
                                <Text>Collection Address</Text>
                                <EditOutlined
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => showModal('collection')}
                                />
                            </Flex>
                        }
                        bordered
                        dataSource={[
                            ...addressText(JSON.parse(activeOrder?.collection_address)).split(', '),
                        ]}
                        renderItem={item => (
                            <List.Item>
                                <ListItem
                                    key={0}
                                    valueText={item}
                                    valueCopyable={(typeof item === 'string') && (activeOrder.postal_code.replace(/\s/g, "") == item.replace(/\s/g, ""))}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            }
            <Col span={8}>
                <List
                    size="small"
                    header={
                        <Flex
                            justify='space-between'
                            align='center'
                        >
                            <Text>Shipping Address</Text>
                            <EditOutlined
                                style={{ cursor: 'pointer' }}
                                onClick={() => showModal('shipping')}
                            />
                        </Flex>
                    }
                    bordered
                    dataSource={[
                        ...addressText(activeOrder).split(', '),
                        <Flex
                            key="shipping-options"
                            wrap
                            vertical
                            align='start'
                        >
                            <Button
                                key="0"
                                size='small'
                                type="link"
                                ghost
                                onClick={exportToRoyalMailHandler}
                                loading={loadingObj.exportRoyalMail}
                                icon={<SendOutlined />}
                            >
                                Create a Royal Mail Label
                            </Button>
                            <Button
                                key="1"
                                size='small'
                                type="link"
                                ghost
                                danger
                                onClick={exportToDPDHandler}
                                loading={loadingObj.exportDPD}
                                icon={<SiDpd />}
                                disabled // DPD API is not implemented
                            >
                                Create a DPD Label
                            </Button>
                        </Flex>
                    ]}
                    renderItem={item => (
                        <List.Item>
                            <ListItem
                                key={0}
                                valueText={item}
                                valueCopyable={(typeof item === 'string') && (activeOrder.postal_code.replace(/\s/g, "") == item.replace(/\s/g, ""))}
                            />
                        </List.Item>
                    )}
                />
            </Col>

            {(activeOrder?.customer_address || activeOrder?.user?.company) &&
                <>
                    <Col span={8}>
                        <List
                            size="small"
                            header={
                                <Flex
                                    justify='space-between'
                                    align='center'
                                >
                                    <Text>Billing Address</Text>
                                    <EditOutlined
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => showModal('billing')}
                                    />
                                </Flex>
                            }
                            bordered
                            dataSource={[
                                ...addressText(activeOrder?.customer_address || activeOrder?.user?.company).split(', '),
                                <>
                                    {activeOrder.status > 2 &&
                                        <>
                                            <Button
                                                key="1"
                                                type='link'
                                                size='small'
                                                onClick={() => getRoyalMailOrderHandler(true, false)}
                                                icon={<BiLinkExternal />}
                                            >
                                                Track Royal Mail
                                            </Button>
                                            <Button
                                                key="0"
                                                size='small'
                                                type="link"
                                                ghost
                                                onClick={() => getRoyalMailOrderHandler(false, true)}
                                                loading={loadingObj.copyTrackingCode}
                                                icon={<TbStatusChange />}
                                            >
                                                Royal Mail Track Code
                                            </Button>
                                        </>
                                    }
                                </>
                            ]}
                            renderItem={item => (
                                <List.Item>
                                    <ListItem
                                        key={0}
                                        valueText={item}
                                        valueCopyable={(typeof item === 'string') && ((activeOrder?.customer_address?.postal_code.replace(/\s/g, "") || activeOrder?.user?.company?.postal_code.replace(/\s/g, "")
                                        ) == item.replace(/\s/g, ""))}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>

                </>
            }

            {activeOrder.user && activeOrder.user.company &&
                <Col span={6}>
                    <List
                        size="small"
                        header={<Text>Business Information</Text>}
                        bordered
                        dataSource={[
                            <ListItem key={0}
                                keyText="Company Name : "
                                valueText={activeOrder.user.company.name}
                                valueCopyable
                            />,
                            ...addressText(activeOrder.user.company).split(', '),
                        ]}
                        renderItem={item => (
                            <List.Item>
                                <ListItem
                                    key={0}
                                    valueText={item}
                                    valueCopyable={(typeof item === 'string') && (activeOrder.user.company?.postal_code.replace(/\s/g, "") == item.replace(/\s/g, ""))}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            }

            {activeOrder.user &&
                <Col span={6}>
                    <List
                        size="small"
                        header={<Text>User Information</Text>}
                        bordered
                        dataSource={[
                            <ListItem key={0}
                                keyText="Name : "
                                valueText={activeOrder.user.name}
                                valueCopyable
                            />,
                            <ListItem key={1}
                                keyText="E-Mail : "
                                valueText={activeOrder.user.email} valueCopyable
                            />,
                        ]}
                        renderItem={item => <List.Item>{item}</List.Item>}
                    />
                </Col>
            }

            <EditAddressFields
                isEditAddressModalOpen={isEditAddressModalOpen}
                setIsEditAddressModalOpen={setIsEditAddressModalOpen}
                editAddressType={editAddressType}
                orderType="repair"
                setActiveKey={setActiveKey}
                activeOrder={activeOrder}
                baseUrl={baseUrl}
            />
        </Row>
    )
}
