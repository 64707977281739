import React, { useCallback, useMemo } from 'react';
import { Modal, Form, Button, DatePicker, Row, Col, Typography, message, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import OrdersApi from '../../../../apis/order-management/repair-order/OrdersApi';

const { Text, Paragraph } = Typography;
const { confirm } = Modal;

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 24 },
  layout: "vertical"
};

export default function CollectionReview({ isModalVisibleCollection, setIsModalVisibleCollection, selectModel, setSelectModel, activeOrder, activeOrderId, baseUrl, setKey, handleCancel, loadingObj, setLoadingObj, mutateCountKey, bankHolidays }) {
  const validateMessages = {
    required: '${label} is required!',
  };
  const [form] = Form.useForm();

  const collection_date = useMemo(() => activeOrder.order_shipments?.find(shipment => shipment.is_shipping === 1 && shipment.type === 0) || null, [activeOrder]);
  const sendingShipment = useMemo(() => activeOrder.order_shipments?.find(shipment => shipment.is_shipping === 1 && shipment.type === 0)?.amount || 0.00, [activeOrder]);

  const handleCancelCollection = () => {
    setSelectModel(null)
    setIsModalVisibleCollection(false);
  };

  const onFinishMarkCollection = async (values) => {
    setLoadingObj({
      ...loadingObj,
      collection: true
    });

    try {
      values.collection_price = sendingShipment;
      values.collection_date = dayjs(values.collection_date).format('YYYY-MM-DD');
      const response = await OrdersApi.markCollectionOrder(baseUrl, activeOrderId, activeOrder?.is_collection, values);
      message.success(response.data);
      setKey(prevState => prevState + 1);

      // Update the count key of the side menu
      mutateCountKey();

      form.resetFields();
      handleCancel();
    } catch (error) {
      console.error('Error sending collection email:', error);
      message.error('Failed to mark collection order. Please try again.');
    } finally {
      setLoadingObj({
        ...loadingObj,
        collection: false
      });
    }
  }

  const onFinishCollectionOrder = async (values) => {
    setLoadingObj(prev => ({ ...prev, collection: true }));

    let isSendCollectionConfirmNotification = false;

    const confirmSendEmail = () => {
      return new Promise((resolve) => {
        confirm({
          title: 'Do you want to send the update to the customer?',
          icon: <ExclamationCircleOutlined />,
          content: 'This will send an email to the customer with the updated collection date.',
          okText: 'Yes',
          cancelText: 'No',
          onOk() {
            resolve(true);
          },
          onCancel() {
            resolve(false);
          },
        });
      });
    };

    try {
      // Wait for customer input
      isSendCollectionConfirmNotification = await confirmSendEmail();

      values.collection_price = sendingShipment;
      values.send_notification = isSendCollectionConfirmNotification;
      values.collection_date = dayjs(values.collection_date).format('YYYY-MM-DD');

      const response = await OrdersApi.collectionOrder(baseUrl, activeOrderId, activeOrder?.is_collection_arranged, values);
      message.success(response.data);
      setKey(prevState => prevState + 1);
      mutateCountKey();
      form.resetFields();
      handleCancel();
    } catch (error) {
      console.error('Error sending collection email:', error);
      message.error('Failed to arrange collection. Please try again.');
    } finally {
      setLoadingObj(prev => ({ ...prev, collection: false }));
    }
  }

  const disabledDate = useCallback((current) => {
    // Disable past dates, dates beyond one year from today, Sundays, and bank holidays
    const today = dayjs().startOf('day');
    const oneYearFromToday = today.add(1, 'year');
    const isPastDate = current && current < today;
    const isBeyondOneYear = current && current > oneYearFromToday;
    const isSunday = current && current.day() === 0;
    const isBankHoliday = bankHolidays.some(holiday => current.isSame(dayjs(holiday.date), 'day'));

    return isPastDate || isBeyondOneYear || isSunday || isBankHoliday;
  }, [bankHolidays]);

  const getDisabledReason = useCallback((current) => {
    const today = dayjs().startOf("day");
    const oneYearFromToday = today.add(1, "year");
    if (current < today) return "Past dates are disabled";
    if (current > oneYearFromToday) return "Dates beyond one year are disabled";
    if (current.day() === 0) return "Sundays are disabled";

    const findBankHoliday = bankHolidays.find((holiday) => current.isSame(dayjs(holiday.date), "day"));
    if (findBankHoliday)
      return findBankHoliday.title;
    return "";
  }, [bankHolidays]);

  const renderDateCell = (current) => {
    if (disabledDate(current)) {
      const reason = getDisabledReason(current);
      return (
        <Tooltip title={reason}>
          <div className="disabled-date">{current.date()}</div>
        </Tooltip>
      );
    }
    return <div>{current.date()}</div>;
  };

  return (
    <Modal
      title={(
        <Row key="row"
          align="middle"
          justify="space-between"
        >
          <Col md={24} className='d-flex justify-between'>
            <Text>{selectModel === 'mark' ? 'Mark as Collection' : 'Collection Arranged'}</Text>
            <Button
              form='collection-update'
              key="submit"
              type="primary"
              htmlType="submit"
              loading={loadingObj.collection}
            >
              Update and Send Notification
            </Button>
          </Col>
        </Row>
      )}
      open={isModalVisibleCollection}
      onCancel={handleCancelCollection}
      closable={false}
      footer={false}
    >
      <Form
        {...layout}
        form={form}
        id="collection-update"
        name="nest-messages"
        onFinish={selectModel === 'mark' ? onFinishMarkCollection : onFinishCollectionOrder}
        className='pt-4'
        validateMessages={validateMessages}
        initialValues={{
          collection_date: collection_date?.collection_date ? dayjs(collection_date?.collection_date) : null,
        }}
      >
        <Row gutter={[24, 0]}>
          <Col span={12}>
            <Form.Item name="collection_date" label="Collection Date" labelCol={{ span: 24 }} rules={[{ required: true }]}>
              <DatePicker
                size='large'
                format="YYYY-MM-DD"
                disabledDate={disabledDate}
                cellRender={renderDateCell}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
