
export const __local__ = process.env.REACT_APP_ENV === 'local';
export const __prod__ = process.env.REACT_APP_ENV === 'production';

export const BACKEND1_URL = process.env.REACT_APP_BACKEND1_URL; // fmbs
export const BACKEND2_URL = process.env.REACT_APP_BACKEND2_URL; // iwatch-repair
export const BACKEND3_URL = process.env.REACT_APP_BACKEND3_URL; // ipad-repair
export const BACKEND4_URL = process.env.REACT_APP_BACKEND4_URL; // mancbookfix
export const BACKEND5_URL = process.env.REACT_APP_BACKEND5_URL; // trade

export const API_ENDPOINT1 = `${process.env.REACT_APP_BACKEND1_URL}/api`;
export const API_ENDPOINT2 = `${process.env.REACT_APP_BACKEND2_URL}/api`;
export const API_ENDPOINT3 = `${process.env.REACT_APP_BACKEND3_URL}/api`;
export const API_ENDPOINT4 = `${process.env.REACT_APP_BACKEND4_URL}/api`;
export const API_ENDPOINT5 = `${process.env.REACT_APP_BACKEND5_URL}/api`;

export const MEDIA1_URL = process.env.REACT_APP_MEDIA1_URL;
export const MEDIA2_URL = process.env.REACT_APP_MEDIA2_URL;
export const MEDIA3_URL = process.env.REACT_APP_MEDIA3_URL;
export const MEDIA4_URL = process.env.REACT_APP_MEDIA4_URL;
export const MEDIA5_URL = process.env.REACT_APP_MEDIA5_URL;
 
export const ZENDESK_USERNAME = process.env.REACT_APP_ZENDESK_USERNAME;
export const ZENDESK_TOKEN = process.env.REACT_APP_ZENDESK_TOKEN;
export const ZENDESK_PASSWORD = process.env.REACT_APP_ZENDESK_PASSWORD;

export const ZENDESK_FMBS_BRAND_ID = process.env.REACT_APP_ZENDESK_FMBS_BRAND_ID;
export const ZENDESK_FMBS_BRAND2_ID = process.env.REACT_APP_ZENDESK_FMBS_BRAND2_ID;

export const ZENDESK_IWATCH_BRAND_ID = process.env.REACT_APP_ZENDESK_IWATCH_BRAND_ID;
export const ZENDESK_IWATCH_BRAND2_ID = process.env.REACT_APP_ZENDESK_IWATCH_BRAND2_ID;

export const ZENDESK_TRADE_BRAND_ID = process.env.REACT_APP_ZENDESK_TRADE_BRAND_ID;

export const GET_ADDRESS_IO_API_KEY = process.env.REACT_APP_GET_ADDRESS_IO_API_KEY;