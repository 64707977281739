import { ExclamationCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Drawer, Modal, Row, Skeleton, Typography, message, notification } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import styles from "../../Orders.module.scss";

import OrdersApi from '../../../../apis/order-management/recycle-order/RecycleOrdersApi';
import GroupOptions from './GroupOptions';
import MoreInfo from './MoreInfo';
import OrderItems from './OrderItems';
import OrderUpdate from './OrderUpdate';
import DrawerWrapper from './DrawerWrapper';
import { store } from '../../../../store';
import { addShippingOrder } from '../../../../store/slices/shippingOrdersRecycleSlice';

const { confirm } = Modal;
const { Text } = Typography;

export default function QuickEdit({ visible, handleHide, handleCancel, handleReset, isNavigable, loadingOrder, setKey, notificationTemplate, baseUrl, roles, activeOrderId, employees, services }) {
    const [modelLoading, setModelLoading] = useState(false);
    const [activeOrder, setActiveOrder] = useState(null);
    const [activeOrderKey, setActiveOrderKey] = useState(0);

    const fetchOrder = async () => {
        setModelLoading(true);
        try {
            const order = await OrdersApi.getOne(baseUrl, activeOrderId);
            setActiveOrder(order);
            setModelLoading(false);
        } catch (error) {
            setModelLoading(false);
        }
    };

    useEffect(() => {
        fetchOrder();
    }, [activeOrderKey]);

    const total = useMemo(() => activeOrder?.total_price ? parseFloat(activeOrder.total_price) : 0, [activeOrder]);

    const [loading, setLoading] = useState(false);

    const handleDeleteUser = async (item) => {
        setLoading(true);
        try {
            await OrdersApi.deleteOne(baseUrl, item.id);
            message.success(`${item.order_no} Order is deleted`);
            setLoading(false);
            setKey(prevState => prevState + 1);
            handleCancel()
        } catch (error) {
            setLoading(false);
            message.error('Something went wrong!');
        }
    }

    const showConfirm = (item) => {
        confirm({
            title: 'Are you sure delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: `${item.order_no}`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDeleteUser(item);
            },
            onCancel() {
                //
            },
        });
    }

    function hasRole(role_name) {
        return roles.some(role => role.name === role_name);
    }
    const openNotification = () => {
        notification.info({
            message: `An item has been added!`,
            description: (
                <a href="#" onClick={(e) => {
                    e.preventDefault();
                    handleHide();
                }} style={{ textDecoration: 'underline' }}>
                    View Basket
                </a>
            ),
            placement: 'bottomRight',
        });
    };

    const addShippingOrderFunction = async () => {
        const state = store.getState();
        const existingOrders = state.shippingOrdersRecyle?.shippingOrdersRecycle;
        const orderExists = existingOrders.some(order => order.id === activeOrder.id);

        if (!orderExists) {
            store.dispatch(addShippingOrder({ id: activeOrder.id }));
        }
        openNotification();
    }
    return (
        <Drawer
            key="order-details-model"
            title={<Row key="row"
                align="middle"
                justify="space-between"
            >
                <Col md={24} className='d-flex' style={{ justifyContent: 'space-between' }}>
                    <Text># {activeOrder?.order_no}</Text>

                    {activeOrder &&
                        <Button
                            primary
                            onClick={addShippingOrderFunction}
                            className={`${styles.button} mx-4`}
                        >
                            <PlusCircleOutlined />
                            Add Basket
                        </Button>
                    }

                </Col>
            </Row>}
            open={visible}
            onClose={handleCancel}
            confirmLoading={modelLoading}
            onCancel={handleCancel}
            afterClose={handleCancel}
            centered
            destroyOnClose
            width={1000}
            push={false}
            footer={(
                <Row
                    key="row"
                    align="middle"
                    justify="space-between"
                >
                    <Col>
                        {(activeOrder && (hasRole('admin') || hasRole('super-admin'))) &&
                            <Button
                                key="submit"
                                danger
                                loading={loading}
                                onClick={() => showConfirm(activeOrder)}
                            >
                                Delete
                            </Button>
                        }
                    </Col>
                    <Col>
                        <Button
                            key="back"
                            onClick={handleCancel}
                        >
                            Back
                        </Button>
                    </Col>
                </Row>
            )}
        >
            {loadingOrder && <Skeleton loading={loadingOrder} avatar paragraph={{ rows: 8 }} />}
            {activeOrder &&
                <div>
                    <Row gutter={16} className={styles.model_card_row}>
                        {activeOrder?.is_collection_arranged &&
                            <Col span={24}>
                                <Alert className='mb-2 w-100' message="This order has already been arranged for collection." type="warning" showIcon />
                            </Col>
                        }
                        <Col span={8}>
                            <OrderUpdate
                                activeOrder={activeOrder}
                                setKey={setKey}
                                handleReset={handleReset}
                                isNavigable={isNavigable}
                                baseUrl={baseUrl}
                            />
                        </Col>
                        <Col span={16}>
                            <GroupOptions
                                baseUrl={baseUrl}
                                activeOrder={activeOrder}
                                activeOrderId={activeOrderId}
                                handleCancel={handleCancel}
                                setKey={setKey}
                                services={services}
                                isPaid={activeOrder?.is_paid === 1}
                            />
                        </Col>
                    </Row>

                    <DrawerWrapper
                        activeOrder={activeOrder}
                        baseUrl={baseUrl}
                        handleReset={handleReset}
                        employees={employees}
                        notificationTemplate={notificationTemplate}
                    />
                    <MoreInfo
                        activeOrder={activeOrder}
                        baseUrl={baseUrl}
                        setActiveOrder={setActiveOrder}
                        setActiveKey={setActiveOrderKey}
                    />
                    <OrderItems
                        activeOrder={activeOrder}
                        total={total}
                        baseUrl={baseUrl}
                        setKey={setKey}
                        setActiveOrderKey={setActiveOrderKey}
                    />
                </div>
            }
        </Drawer>
    )
}
