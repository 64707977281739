import React, { useState } from 'react'
import { Button, message, Modal, Popover } from 'antd'
import { CloseCircleOutlined, ExclamationCircleOutlined, MailOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

import styles from '../Orders.module.scss';

import OrdersApi from '../../../apis/order-management/recycle-order/RecycleOrdersApi';
import { addShippingOrder } from '../../../store/slices/shippingOrdersRecycleSlice';
import { store } from '../../../store';

const { confirm } = Modal;

export default function SelectedOptions({ selectedRowKeys, selectedRows, setIndex, rowsUnCheckHandler, baseUrl }) {
    const [loadingObj, setLoadingObj] = useState({
        printLabel: false,
        testPrintLabel: false,
        resendOrderReceivedMail: false,
        sendOrderReminder: false,
    });

    const showConfirmDeleteOne = () => {
        confirm({
            title: 'Are you sure delete this item(s)?',
            icon: <ExclamationCircleOutlined />,
            content: `${selectedRowKeys.length} Item(s)`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteOneSelectedRow()
            },
            onCancel() {
                //
            },
        });
    };

    const deleteOneSelectedRow = async () => {
        try {
            const selectFirstItem = selectedRows[0];
            await OrdersApi.deleteOne(baseUrl, selectFirstItem.id);
            message.success(`${selectFirstItem.order_no} - Order is deleted`);
            setIndex(prevIndex => prevIndex + 1)
        } catch (error) {
            message.error('Something went wrong!');
        }
    }


    const sendOrderReminderHandler = async () => {
        setLoadingObj({
            ...loadingObj,
            sendOrderReminder: true,
        });

        try {

            const response = await OrdersApi.sendOrderReminderBulk(baseUrl, selectedRowKeys);
            message.success(response.success);

            setLoadingObj({
                ...loadingObj,
                sendOrderReminder: false
            });
        }
        catch (e) {
            message.error(e.message ?? 'Something went wrong!');
            setLoadingObj({
                ...loadingObj,
                sendOrderReminder: false
            });
        }
    }

    const addShippingOrderFunction = async () => {

        const state = store.getState();
        const existingOrders = state.shippingOrdersRecyle?.shippingOrdersRecycle;

        selectedRowKeys.forEach(orderId => {
            const orderExists = existingOrders.some(order => order.id === orderId);

            if (!orderExists) {
                store.dispatch(addShippingOrder({ id: orderId }));
            }
        });
        rowsUnCheckHandler();
    }

    return (
        <>
            {selectedRowKeys && selectedRowKeys.length > 0 &&
                <div>
                    <Button
                        primary
                        onClick={rowsUnCheckHandler}
                        className={styles.button}
                    >
                        <MinusCircleOutlined />
                        Uncheck All
                    </Button>

                    <Button
                        primary
                        onClick={addShippingOrderFunction}
                        className={styles.button}
                    >
                        <PlusCircleOutlined />
                        Add Basket
                    </Button>

                    {selectedRowKeys.length === 1 &&
                        <>
                            <Button
                                danger
                                onClick={showConfirmDeleteOne}
                                className={styles.button}
                            >
                                <CloseCircleOutlined />
                                Delete One
                            </Button>
                        </>
                    }

                    <Popover content="Send the Order Reminder" placement="top">
                        <Button
                            primary
                            loading={loadingObj.sendOrderReminder}
                            className={styles.button}
                            onClick={sendOrderReminderHandler}
                        >
                            <MailOutlined />
                            Reminder
                        </Button>
                    </Popover>

                    <p className={styles.text}>Selected item(s) {selectedRowKeys.length}</p>
                </div>
            }
        </>
    )
}
